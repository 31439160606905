import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import styles from "./aboutAuthor.module.scss";

//import { StaticImage } from "gatsby-plugin-image"
// <StaticImage src={'tony.jpg'} alt={'Tony'} imgClassName={styles.aboutTony__img} width={289} height={290}  />

// import avatar_png from "./img/avatar.png";
// import avatar_md_png from "./img/avatar_md.png";
// import avatar_lg_png from "./img/avatar_lg.png";
// import avatar_xl_png from "./img/avatar_xl.png";
//
// import tony_png from "./img/tony.png";
// import tony_md_png from "./img/tony_md.png";
// import tony_lg_png from "./img/tony_lg.png";
// import tony_xl_png from "./img/tony_xl.png";
//
// import avatar from "./img/avatar.webp";
// import avatar_md from "./img/avatar_md.webp";
// import avatar_lg from "./img/avatar_lg.webp";
// import avatar_xl from "./img/avatar_xl.webp";

import tony_jpg from "./img/tony_sm.png"
import polina_jpg from "./img/polina_sm.png"
import danny_jpg from "./img/danny.jpg"
import ali_jpg from "./img/ali.jpg"
import kerri_jpg from "./img/kerri.jpg"
import jeanne_jpg from "./img/jeanne.jpg"

import style from "../contact/contact.module.scss"


// <picture>
// <source media="(min-width: 1140px)" srcSet={avatar_xl} type="image/webp"/>
//     <source media="(min-width: 1140px)" srcSet={avatar_xl_png} type="image/png"/>
//
//     <source media="(min-width: 960px)" srcSet={avatar_lg} type="image/webp"/>
//     <source media="(min-width: 960px)" srcSet={avatar_lg_png} type="image/png"/>
//
//     <source media="(min-width: 720px)" srcSet={avatar_md} type="image/webp"/>
//     <source media="(min-width: 720px)" srcSet={avatar_md_png} type="image/png"/>
//
//     <source media="(max-width: 719px)" srcSet={avatar} type="image/webp"/>
//     <img className={styles.aboutAuthor__img} src={avatar_png} alt="main banner" width="289px" height="286px"/>
//     </picture>
// <picture>
// <source media="(min-width: 1140px)" srcSet={tony_xl_png} type="image/png"/>
//
//     <source media="(min-width: 960px)" srcSet={tony_lg_png} type="image/png"/>
//
//     <source media="(min-width: 720px)" srcSet={tony_md_png} type="image/png"/>
//
//     <img className={styles.aboutTony__img} src={tony_png} alt="main banner" width="289px" height="290px"/>
//     </picture>



function AboutAuthor() {
  return (
      <>
        <div className={`${styles.aboutAuthor} container`}>
          <div className={styles.aboutAuthor__content}>

            <picture>
              <source media="(min-width: 1140px)" srcSet={polina_jpg} type="image/jpg"/>
              <source media="(min-width: 960px)" srcSet={polina_jpg} type="image/jpg"/>
              <source media="(min-width: 720px)" srcSet={polina_jpg} type="image/jpg"/>
              <img className={styles.aboutAuthor__img} src={polina_jpg} alt="Polina" height="289px"/>
            </picture>

            <p className={styles.aboutAuthor__imgText}>
              Polina Shneyderman, Lic Ac <OutboundLink href="https://nccaom.org">NCCAOM</OutboundLink> ®
              Diplomate of Oriental Medicine
            </p>
          </div>
          <div className={styles.aboutAuthor__text}>
            <p>Polina discovered her passion for Acupuncture and Chinese Medicine when she saw how much relief it
              provided
              to her loved ones with pain and chronic illnesses. </p>
            <br/>
            <p>Polina has a Masters Degree in Acupuncture and Oriental Medicine from the New England School of
              Acupuncture
              at Massachusetts College of Pharmacy and Health Sciences. She is licensed as an Acupuncturist and
              Herbalist
              in
              the state of Massachusetts and is recognized by the National Certification Commission for Acupuncture and
              Oriental Medicine (<OutboundLink href="https://nccaom.org">NCCAOM</OutboundLink>).</p>
            <br/>
            <p>Polina is a winner of <a href={'https://www.thebestvendor.com/wea23-wellness/'}>Women Entrepreneur 2023
              Award</a> in Health &amp; Wellness Category</p>
            <br/>
            <p>Polina treats both chronic and acute conditions. She offers balancing treatments to maintain health and
              wellbeing. Polina will give you her utmost care and attention and invite you to be an active participant
              in
              your treatment. She will work with you to diminish pain, balance your body and mind, and find your way to
              a
              healthier you. </p>
            <br/>
            <p>Polina accepts insurance. To check if your insurance benefits include acupuncture, please click <Link
                to="/#contacts">Contact Us</Link>.</p>
          </div>
        </div>
        <div className={`${styles.aboutAuthor} container`}>
          <div className={styles.aboutAuthor__content}>

            <picture>
              <source media="(min-width: 1140px)" srcSet={kerri_jpg} type="image/jpg"/>
              <source media="(min-width: 960px)" srcSet={kerri_jpg} type="image/jpg"/>
              <source media="(min-width: 720px)" srcSet={kerri_jpg} type="image/jpg"/>
              <img className={styles.aboutKerry__img} src={kerri_jpg} alt="Kerri" height="330px"/>
            </picture>

            <p className={styles.aboutAuthor__imgText}>
              Kerri Laneau, Lic Ac <OutboundLink href="https://nccaom.org">NCCAOM</OutboundLink> ®
              Diplomate of Oriental Medicine
            </p>
          </div>
          <div className={styles.aboutAuthor__text}>
            <p>Kerri holds a Masters in Acupuncture and Chinese Herbal Medicine from the New England School of
              Acupuncture, with a specialization in Japanese Acupuncture. She also has a BS in Biology from Yale
              University and a Masters in Wine Business from the University of Adelaide.</p>
            <br/>
            <p>Kerri was introduced to acupuncture through her own fertility journey where she discovered its profound
              healing potential. She fosters a collaborative, supportive environment where patients feel empowered
              to take charge of their health and wellness journey. She treats both chronic and acute conditions.</p>
            <br/>
            <p>Kerri accepts insurance. To check if your insurance benefits include acupuncture, please click <Link
                to="/#contacts">Contact Us</Link>.</p>
          </div>
        </div>
        <div className={`${styles.aboutAuthor} container`}>
          <div className={styles.aboutAuthor__content}>

            <picture>
              <source media="(min-width: 1140px)" srcSet={ali_jpg} type="image/jpg"/>
              <source media="(min-width: 960px)" srcSet={ali_jpg} type="image/jpg"/>
              <source media="(min-width: 720px)" srcSet={ali_jpg} type="image/jpg"/>
              <img className={styles.aboutAli__img} src={ali_jpg} alt="Ali" width="436px" height="400px"/>
            </picture>

            <p className={styles.aboutAuthor__imgText}>
              Ali Buzzeo, Lic Ac <OutboundLink href="https://nccaom.org">NCCAOM</OutboundLink> ®
              Diplomate of Oriental Medicine
            </p>
          </div>
          <div className={styles.aboutAuthor__text}>
            <p>Ali Buzzeo holds a Masters in Acupuncture from the New England School of Acupuncture at Massachusetts
              College of Pharmacy and Health Sciences, as well as a Masters in Psychology from New York University.</p>
            <br/>
            <p>While working as a child behavioral therapist, Ali observed that patients responded more effectively to
              sensory therapies than traditional methods, which led her to explore holistic healing and
              energy medicine.</p>
            <br/>
            <p>“I started to recognize the body as a gateway to regulating the nervous system, which sparked my interest
              in
              energy medicine and acupuncture.”</p>
            <br/>
            <p>Ali helps patients find emotional and physical balance through acupuncture, rooted in mind-body
              connection.
              Her approach combines spirituality, psychology, and energy medicine. In addition to anxiety, depression,
              and
              other emotional challenges, Ali treats both acute and chronic pain, insomnia, and digestive disorders.</p>
            <br/>
            <p>Ali accepts insurance. To check if your insurance benefits include acupuncture, please click <Link
                to="/#contacts">Contact Us</Link>.</p>
          </div>
        </div>
        <div className={`${styles.aboutAuthor} container`}>
          <div className={styles.aboutAuthor__content}>

            <picture>
              <source media="(min-width: 1140px)" srcSet={jeanne_jpg} type="image/jpg"/>
              <source media="(min-width: 960px)" srcSet={jeanne_jpg} type="image/jpg"/>
              <source media="(min-width: 720px)" srcSet={jeanne_jpg} type="image/jpg"/>
              <img className={styles.aboutJeanne__img} src={jeanne_jpg} alt="Jeanne" height="291px"/>
            </picture>

            <p className={styles.aboutAuthor__imgText}>
              Dr. Jeanne MacDonald
            </p>
          </div>
          <div className={styles.aboutAuthor__text}>
            <p>
              Dr. Jeanne MacDonald has been a pediatrician at Mass General for over 20 years. She practices palliative
              care with a focus on symptom management and quality of life. Jeanne completed acupuncture training
              for physicians through the University of New England, and acupuncture is now part of her extensive
              tool kit of therapies.
            </p>
            <br/>
            <p>
              At AcuBoston, Jeanne treats patients with pain, migraines, chronic conditions like Lyme disease
              and diabetes, OBGYN issues, digestive problems, and pediatrics.
            </p>
            <br/>
            <p>Jeanne accepts insurance. To check if your insurance benefits include acupuncture, please click <Link
                to="/#contacts">Contact Us</Link>.</p>
          </div>
        </div>
      </>
  )
}

export default AboutAuthor